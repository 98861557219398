import React from 'react';
import {
  Container,
  Image,
  Navbar,
} from 'react-bootstrap';
import Logo from '../../assets/logo.svg';
import './Header.scss';

export const Header: React.FC = () => {

  return (
    <Navbar expand="sm" className={ 'p-2 pt-3 header-fonts' } sticky={'top'} bg={'white'}>
      <Container fluid={ 'sm' } className={ 'ms-2 me-2 p-0 max-width-100 align-items-center' }>
        <Navbar.Brand className={ 'mr-5 ms-1' } href={'/'}>
          <Image src={ Logo } width={ 100 }/>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};