import React from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css";
import { Header } from './components/Header/Header';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <SwaggerUI url={"https://api.intramark.io/assets/intramark_documents.json"} />
    </div>
  );
}

export default App;
